/* Layout generale */
.layout {
  display: flex;
  height: 100vh;
}

/* Sidebar generale */
.sidebar {
  width: 250px;
  background-color: #222;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto; /* Abilita lo scroll verticale se il contenuto eccede l'altezza della viewport */
}

/* Evita che la scrollbar copra i contenuti della sidebar */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

/* La brand rimane in cima e non scorre */
.sidebar .brand {
  margin-bottom: 20px;
  text-align: center;
  position: sticky;
  top: 0;
  background-color: #222;
  z-index: 10;
  padding-bottom: 10px;
}



.sidebar .brand {
  margin-bottom: 20px;
  text-align: center;
}

.profile-pic {
  width: 100px;
  height: 100px;
  background-color: #ff9800;
  border-radius: 50%;
  margin: 0 auto 10px;
  border: 2px solid #fff;
}

.sidebar nav {
  flex-grow: 1;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar nav ul li {
  margin-bottom: 10px;
  padding-left: 20px;
}

.sidebar nav ul li a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 5px 0;
  font-size: 18px;
  text-align: left;
}

.sidebar nav ul li a.active-section {
  color: #ff9800;
}

.sidebar nav ul li a:hover {
  color: #ff9800;
}

/* Footer */
.footer {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: #222;
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer .social-links {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.footer .social-links a {
  color: #fff;
  margin-left: 10px;
  text-decoration: none;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.footer .social-links a:hover {
  color: #ff9800;
}

/* Main Section */
.main-section {
  margin-left: 250px;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  padding-top: 100px; /* Compensa l'header fisso */
}

/* Header */
.header {
  position: fixed;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header h1 {
  margin: 0;
  font-size: 24px;
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-bar input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

.search-bar button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #222;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: #444;
}

/* Main Content */
.main-content {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

/* Wrapper per i post, occupa 2/3 dello spazio disponibile */
.posts-wrapper {
  flex: 2; /* Occupa 2/3 dello spazio */
}

/* Wrapper per la sidebar, occupa 1/3 dello spazio disponibile */
.right-wrapper {
  flex: 2; /* Occupa 1/3 dello spazio */
}

/* Posts section */
.posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.post-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 18rem;
  height: 100%;
}

.post-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
}

/* PostCard orizzontale */
.post-card-orizzontal {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 15px;
  padding: 20px;
}

.post-card-orizzontal img {
  width: 150px; /* Larghezza fissata per desktop */
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.post-card-content {
  flex: 1;
}

.post-card-title {
  font-size: 16px;
  margin: 0 0 5px;
}

.post-card-excerpt {
  font-size: 14px;
  color: #555;
  margin: 0;
  line-height: 1.4;
}

/* Right Sidebar Widgets */
.right-sidebar {
  background-color: #fff;
  padding: 20px;
  border-left: 1px solid #ddd;
}

.widget {
  margin-bottom: 20px;
}

.widget h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.widget ul li img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.widget ul li p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.widget ul li a {
  text-decoration: none;
  color: #000;
}

.widget ul li a:hover {
  text-decoration: underline;
}

/* Hamburger Menu */
.hamburger-menu {
  display: none;
  position: fixed;
  top: 10px;
  right: 20px;
  background-color: #000;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.hamburger-menu.active span:nth-child(1) {
  transform: rotate(-45deg) translate(-9px, 6px);
}

.hamburger-menu.active span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.active span:nth-child(3) {
  transform: rotate(45deg) translate(-8px, -8px);
}

.hamburger-menu span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  transition: 0.4s;
}

/* Sidebar Search Bar */
.sidebar .search-bar {
  display: flex;
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  margin-bottom: 20px;
  display: none;
}

.sidebar .search-bar input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  margin-right: 10px;
  background-color: #fff;
}

.sidebar .search-bar button {
  padding: 10px;
  background-color: #ff9800;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.close-btn {
  display: none;
}

.hamburger-container {
  display: none;
}

/* Stili generali per il mobile */
@media (max-width: 768px) {
  .hamburger-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    padding: 10px;
    height: 70px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
  }

  .hamburger-container .hamburger-menu {
    display: block;
    padding: 10px;
    border-radius: 5px;
  }

  .hamburger-container .hamburger-menu span {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
  }

  .hamburger-container .brand-name {
    color: #fff;
    font-size: 20px;
    margin-left: 10px;
    text-align: center;
  }

  .brand {
    display: none;
  }

  .header {
    margin-top: 50px;
    display: none;
  }

  .sidebar {
    display: none;
  }

  .sidebar.active {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
    padding-top: 60px;
  }

  /* Sidebar Search Bar */
.sidebar .search-bar {
  display: flex;
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  margin-bottom: 20px;
  position: sticky; /* Rende la search bar sticky */
  top: 0; /* Fissa la search bar in cima alla sidebar */
  z-index: 10; /* Assicura che la search bar rimanga sopra gli altri elementi */
}


  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    z-index: 1001;
  }

  .main-section {
    margin-left: 0;
  }
  .post-card {
    width: 100%; /* Assicurati che la card prenda tutta la larghezza disponibile */
  }
  
  .post-card img {
    width: 100%; /* L'immagine si espande per occupare tutta la larghezza del contenitore */
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }

  .main-content {
    flex-direction: column;
  }

  .posts-wrapper, .right-wrapper {
    flex: 1 1 100%;
  }
  .post-card-orizzontal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    margin-bottom: 15px;
    padding: 20px;
  }
  .post-card-orizzontal img {
    width: 100%; /* L'immagine orizzontale si espande in modalità mobile */
    height: auto;
    object-fit: cover;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
